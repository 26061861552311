import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import { VideoBackground } from './VideoBackground'
import Link from 'next/link'

export const DownloadsCallout: React.FC = () => {
  return (
    <Link href='/downloads'>
      <Box position='relative' textAlign='center' backgroundColor='black'>
        <div style={{ opacity: 0.5 }}>
          <VideoBackground
            image=''
            video='/img/downloads-video-background.mp4'
          />
        </div>
        <Box zIndex={1} py={{ base: 20, xl: 36 }} px={4} pos='relative'>
          <Stack spacing={4}>
            <Heading
              as='h2'
              fontSize={{ base: '4xl', lg: '8xl' }}
              lineHeight={1}
              textTransform='uppercase'
              color='white'
              style={{ textDecoration: 'none !important', wordWrap: 'normal' }}>
              Download Game Mods, <br /> Custom Gauges, and More…
            </Heading>
            <div>
              <Button>Get Downloads</Button>
            </div>
          </Stack>
        </Box>
      </Box>
    </Link>
  )
}
