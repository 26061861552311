import {
  Badge,
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'
import Carousel from './carousel/carousel'
import { MemberSpotlightArticle } from './MemberSpotlightArticle/MemberSpotlightArticle.types'
import Link from 'next/link'

export const HomePageHero = (props: { articles: MemberSpotlightArticle[] }) => {
  return (
    <>
      <Box backgroundColor='red.500'>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          textAlign='center'
          p={4}
          justify='center'
          gap={4}
          align='center'>
          <Text color='white' as='div'>
            Win a Grandfalls Retractable Pressure Washer PRO+ Grandstorm
            Retractable Vacuum Cleaner
          </Text>
          <div>
            <Button as='a' href='/contest'>
              Enter Giveaway
            </Button>
          </div>
        </Stack>
      </Box>
      <Box
        backgroundImage='url(/img/nationHeroBackground.webp)'
        backgroundSize='cover'
        p={{ base: 4, lg: 12 }}
        pb={{ base: 0, lg: 0 }}
        backgroundColor='blue.900'
        color='white'>
        <Stack
          mt='auto'
          mb='auto'
          textAlign='center'
          py={{ base: 12, lg: 0 }}
          px={{ base: 0, lg: 8 }}>
          <Box>
            <Heading
              as='h1'
              fontSize={{ base: 'lg', lg: 'xl' }}
              textTransform='uppercase'
              mb={3}
              mx='auto'>
              From American Muscle to Classics to&nbsp;Diesels
            </Heading>
            <Heading
              as='h2'
              fontSize={{ base: '5xl', lg: '8xl' }}
              lineHeight={1}
              textTransform='uppercase'>
              Project cars from
              <br />
              around the&nbsp;world
            </Heading>
            <Heading
              as='h3'
              mt={4}
              fontSize={{ base: 'xl', lg: '4xl' }}
              lineHeight={1}
              textTransform='uppercase'>
              Share your builds, be inspired
            </Heading>
          </Box>
        </Stack>
        {props.articles && <Spotlight articles={props.articles} />}
      </Box>
    </>
  )
}

type SpotlightProps = {
  articles: MemberSpotlightArticle[]
}

const Spotlight = (props: SpotlightProps) => {
  return (
    <Box
      mx='auto'
      maxW='4xl'
      pb={{ base: 8, lg: 12 }}
      style={{
        backgroundImage:
          'linear-gradient(rgba(78,103,147,0), rgba(78,103,147,1))',
      }}>
      <Carousel options={{ loop: true }}>
        {props.articles.map(article => (
          <Box className='embla__slide' key={article.slug}>
            <Container maxW='3xl' mx='auto'>
              <Link href={`/spotlight/${article.slug}`}>
                <Stack align='start'>
                  {article?.hero_image?.url ? (
                    <Image
                      alt={article.hero_image.alt ?? ''}
                      height={article.hero_image.height}
                      src={article.hero_image.url}
                      width={article.hero_image.width}
                      style={{
                        marginInline: 'auto',
                        width: '100%',
                        maxWidth: '900px',
                        marginTop: '80px',
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Stack gap={3} style={{ marginTop: '-36px' }}>
                    <Stack align='start'>
                      <Badge backgroundColor='cyan.400' color='white'>
                        Spotlight
                      </Badge>
                      <Heading size='4xl'>{article.title}</Heading>
                    </Stack>
                    <Box maxW='xl'>
                      {article.highlighted_quote ? (
                        <Box pos='relative'>
                          <Text as='span' fontSize='lg'>
                            {article.highlighted_quote}
                          </Text>
                        </Box>
                      ) : (
                        <Box pos='relative'>{article.intro}</Box>
                      )}
                    </Box>
                    <Stack direction='row' align='center' gap={4}>
                      <Button colorScheme='red'>See Build Story</Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Link>
            </Container>
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}
