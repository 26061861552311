import { Box } from '@chakra-ui/react'
import React from 'react'

type VideoBackgroundProps = {
  image: string
  video: string
}

export const VideoBackground: React.FC<VideoBackgroundProps> = props => {
  // Pause video on tap
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [forcePaused, setForcePaused] = React.useState(false)
  React.useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener('click', () => {
        if (isPlaying) {
          video.pause()
          setIsPlaying(false)
          setForcePaused(true)
        } else {
          video.play()
          setIsPlaying(true)
          setForcePaused(false)
        }
      })
    }
  }, [isPlaying])

  // Pause video on scroll out of view
  React.useEffect(() => {
    const handleScroll = () => {
      const video = videoRef.current
      if (video) {
        const rect = video.getBoundingClientRect()
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          if (!forcePaused) {
            video.play()
            setIsPlaying(true)
          }
        } else {
          video.pause()
          setIsPlaying(false)
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [forcePaused])

  // Play video on load after 3 seconds
  React.useEffect(() => {
    setTimeout(() => {
      if (forcePaused) return
      const video = videoRef.current
      if (video) {
        video.play()
        setIsPlaying(true)
        setForcePaused(false)
      }
    }, 1500)
  }, [forcePaused])

  // Pause video if user leaves the tab
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      const video = videoRef.current
      if (video) {
        if (document.hidden) {
          video.pause()
          setIsPlaying(false)
        } else {
          if (!forcePaused) {
            video.play()
            setIsPlaying(true)
          }
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [forcePaused])

  return (
    <Box bgColor='gray.100' overflow='hidden' position='absolute' inset={0}>
      <video
        poster={props.image}
        ref={videoRef}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'bottom',
          display: 'block',
          animationName: 'fadeIn',
          animationDuration: '2s',
          animationFillMode: 'forwards',
          animationTimingFunction: 'ease-in-out',
          animationIterationCount: 'initial',
        }}
        muted
        playsInline
        controls={false}
        loop
        preload='true'
        src={props.video}
      />
    </Box>
  )
}
