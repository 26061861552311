import { BuildList, BuildListData } from '@/components/BuildList'
import { DownloadsCallout } from '@/components/DownloadsCallout'
import { HeavyDutyCallout } from '@/components/HeavyDutyCallout'
import { HomePageHero } from '@/components/HomePageHero'
import {
  partnerListData,
  PartnersAnimated,
} from '@/components/PartnersAnimated'
import { StoreCallout } from '@/components/StoreCallout'
import { YoutubeCallout } from '@/components/YoutubeCallout'
import { statamicGraphqlEndpoint } from '@/globals/globals'
import { getShopifyProducts } from '@/lib/getShopifyProducts'
import { getYouTubeStats } from '@/lib/youtube/getYouTubeStats'
import { Box, Container, Grid, GridItem, Heading, Link } from '@chakra-ui/react'
import { request } from 'graphql-request'
import { GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'
import Image from 'next/image'
import NextLink from 'next/link'

export const getStaticProps: GetStaticProps = async () => {
  let partnerData = await request(statamicGraphqlEndpoint, partnerListData())

  let partnersDivWidth = 0

  partnerData.entries.data.forEach((data: any) => {
    partnersDivWidth = data.logo
      ? partnersDivWidth + data.logo?.width / (data.logo?.height / 40)
      : 0
  })

  const spotlightArticlesQuery = await request(
    statamicGraphqlEndpoint,
    `query MyQuery {
        entries(collection: "member-spotlight-articles") {
          total
          data {
            ... on Entry_MemberSpotlightArticles_MemberSpotlightArticles {
              intro
              slug
              title
              highlighted_quote
              feature
              hero_image {
                ... on Asset_Main {
                  id
                  url
                  width
                  alt
                  height
                }
              }
            }
          }
        }
      }`
  )

  // Limit to 5 articles
  const spotlightArticles = spotlightArticlesQuery.entries.data.slice(0, 4)

  const DGYouTubeStats = await getYouTubeStats('UCLXV9vzJwgYOyGaZlD89VfA')
  const HDYouTubeStats = await getYouTubeStats('UCSmNtj5yLZ-WvwqrrMiTYvw')

  // Get Shopify products
  const productResponse = await getShopifyProducts({
    collectionID: 286_842_192_028,
    limit: 8,
  })
  const products = productResponse.collection.products.nodes

  const builds = await request(statamicGraphqlEndpoint, BuildListData(), {
    limit: 6,
  })

  return {
    props: {
      builds,
      DGYouTubeStats,
      HDYouTubeStats,
      partners: partnerData,
      partnersDivWidth: partnersDivWidth,
      spotlightArticles,
      products,
    },
    revalidate: 60,
  }
}

export default function Page(props: any) {
  return (
    <>
      <NextSeo
        title='Deboss Garage | The Best Custom Car Build Community on the Internet! '
        description='From American Muscle to Classics to Diesels, BUILD / SWAP / REPAIR Anything with a Throttle.'
      />
      <HomePageHero articles={props.spotlightArticles} />
      <PartnersAnimated
        partners={props.partners}
        partnersDivWidth={props.partnersDivWidth || '5000'}
      />
      <StoreCallout products={props.products} />
      <Container maxW='7xl' py={{ base: 10, xl: 20 }}>
        <Heading textAlign='center'>Deboss Garage Builds</Heading>
        <BuildList builds={props.builds} />
        <Box px={8} textAlign='center'>
          <NextLink href='/builds' passHref legacyBehavior>
            <Link fontSize='lg'>See All Builds ›</Link>
          </NextLink>
        </Box>
      </Container>
      <Box maxW='7xl' mx='auto' pb={{ base: 0, xl: 20 }}>
        <Link href='/nation/map/projects'>
          <Image
            src='/img/project-map-callout.webp'
            alt='View Project Map'
            width='2948'
            height='1258'
          />
        </Link>
      </Box>
      <DownloadsCallout />
      <Grid gridTemplateColumns={{ base: '1fr', xl: '1fr 1fr' }} gap={0}>
        <GridItem>
          <YoutubeCallout stats={props.DGYouTubeStats} />
        </GridItem>
        <GridItem>
          <HeavyDutyCallout stats={props.HDYouTubeStats} />
        </GridItem>
      </Grid>
    </>
  )
}
