import { numberWithCommas } from '@/lib/numberWithCommas'
import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import NextImage from 'next/legacy/image'

export const HeavyDutyCallout = (props: any) => {
  return (
    <Box position='relative' overflow='hidden' height='100%'>
      <Box pos='absolute' inset={0} zIndex={1}>
        <NextImage
          src='/img/heavy-duty/deboss-heavy-duty-background.webp'
          layout='fill'
          alt=''
          objectPosition='center'
          objectFit='cover'
        />
      </Box>
      <Stack gap={8}>
        <Box
          pos='relative'
          maxWidth='440px'
          zIndex={2}
          margin='0 auto'
          display='flex'
          justifyContent='center'
          flexDirection='column'>
          <NextImage
            src='/img/heavy-duty/deboss-heavy-duty-logo.webp'
            alt='Deboss Heavy Duty TV'
            width='880'
            height='360'
          />
          {props.stats && (
            <Heading
              mt={4}
              fontSize='xl'
              color='black'
              textAlign='center'
              textDecoration='none !important'
              width='full'>
              {numberWithCommas(props.stats?.subscriberCount)} Subscribers
            </Heading>
          )}
        </Box>
        <Box
          pos='relative'
          display='flex'
          alignItems='center'
          justifyContent='center'
          zIndex={3}>
          <Button
            href='https://www.youtube.com/c/DGHD2'
            size='lg'
            as='a'
            target='_blank'>
            Watch DGHD
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
